import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";

import { Layout, RandomOp, Typography } from "../../components";
import {
	Figure,
	FigureDouble,
	List,
	MainPart,
	MainTitle,
} from "../../components/opComponents";

const ProtheseDepaule = () => (
	<Layout
		title="Prothèse d'épaule : Diagnostic et traitement"
		description="La mise en place d'une prothèse d'épaule est indiquée en cas d'usure importante du cartilage articulaire ou de fracture complexe de l'épaule."
		headerStyle="white"
	>
		<div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
			<Link
				to="/chirurgie-de-lepaule"
				className="flex flex-row items-center space-x-3 uppercase text-primary group"
			>
				<FontAwesomeIcon
					className="transition-all duration-300 ease-in-out group-hover:text-link"
					icon="long-arrow-alt-left"
				/>
				<Typography
					className="group-hover:text-link"
					weight="semiBold"
					transition="medium"
				>
					Retour aux opérations de l&apos;épaule
				</Typography>
			</Link>
			<div className="flex flex-col space-y-6">
				<MainTitle currentOpTitle="Prothèse d'épaule" />
				<MainPart title="Comment est articulée l'épaule ?">
					<Typography>
						Sur le plan osseux, l&apos;articulation de l&apos;épaule est
						constituée de trois structures : l&apos;extrémité proximale de
						l&apos;humérus, la scapula (ou omoplate) et la clavicule (Figure 1).
					</Typography>
					<Figure
						imageName="figure1-prothesedepaule.jpg"
						caption="Figure 1. Anatomie osseuse de l'épaule"
						currentOpTitle="Prothèse d'épaule"
					/>
					<Typography>
						Trois articulations sont ainsi présentes au niveau de l&apos;épaule
						:
					</Typography>
					<List>
						<List.Item>
							<Typography weight="bold">
								L&apos;articulation scapulo-humérale
								<Typography variant="span">
									{" "}
									: entre la scapula et l&apos;extrémité proximale de
									l&apos;humérus
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Une articulation acromio-claviculaire
								<Typography variant="span">
									{" "}
									: entre l&apos;acromion et la clavicule
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								L&apos;articulation scapulo-thoracique
								<Typography variant="span">
									{" "}
									: située à l&apos;arrière de l&apos;épaule, entre la scapula
									et la cage thoracique, il s&apos;agit plutôt d&apos;un espace
									de glissement
								</Typography>
							</Typography>
						</List.Item>
					</List>
					<Typography>
						La mobilité de l&apos;épaule est possible grâce à la présence de
						différents types de muscles (Figure 2) :
					</Typography>
					<FigureDouble
						maxWidth="max-w-[500px]"
						height="h-[300px]"
						imageNameOne="figure2-prothesedepaule.jpg"
						imageNameTwo="figure2bis-prothesedepaule.jpg"
						caption="Figure 2. Muscles de l'épaule: la coiffe des rotateurs (sous-scapulaire, supra-épineux, infra-épineux, petit rond), le muscle deltoïde et le muscle biceps brachial"
						currentOpTitle="Prothèse d'épaule"
					/>
					<List>
						<List.Item>
							<Typography weight="bold">
								Le deltoïde,
								<Typography variant="span">
									{" "}
									muscle puissant qui donne le galbe de l&apos;épaule
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Les muscles de la coiffe des rotateurs
								<Typography variant="span">
									{" "}
									qui s&apos;insèrent sur les tubérosités de l&apos;extrémité
									proximale de l&apos;humérus
								</Typography>
							</Typography>
						</List.Item>
					</List>
				</MainPart>
				<MainPart title="Quelles sont les indications pour la mise en place d'une prothèse de l'épaule ?">
					<Typography>
						La mise en place d&apos;une prothèse d&apos;épaule est indiquée en
						cas{" "}
						<Typography variant="span" weight="bold">
							d&apos;usure importante du cartilage articulaire (arthrose)
						</Typography>
						, en cas{" "}
						<Typography variant="span" weight="bold">
							fracture complexe de l&apos;épaule
						</Typography>{" "}
						et en cas de lésion très importante et non réparable des tendons de
						la coiffe des rotateurs. Elle permet de soulager la douleur de façon
						durable et de redonner de la force et de la mobilité à
						l&apos;épaule. Le traitement médical doit toujours être tenté (sauf
						en cas de fracture) avant d&apos;envisager la chirurgie :
						rééducation (gain d&apos;amplitude et de force musculaire),
						infiltration écho-guidée de corticoïdes et/ou d&apos;acide
						hyaluronique.
					</Typography>
					<List>
						<List.Item>
							<Typography weight="bold">
								Usure des surfaces osseuses articulaires (arthrose) :
								<Typography variant="span">
									{" "}
									L&apos;usure du cartilage articulaire aboutit à un contact
									direct entre les os, responsable de douleurs et de limitation
									des mouvements de l&apos;épaule. Cette usure peut être
									d&apos;origine mécanique (arthrose primitive) ou secondaire à
									une pathologie préexistante : maladie inflammatoire
									(polyarthrite rhumatoïde), microcristalline, ostéonécrose,
									séquelle de fracture....
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Fracture de l&apos;épaule :
								<Typography variant="span">
									{" "}
									En cas de fracture complexe et déplacée de l&apos;épaule,
									compromettant la vascularisation de la tête humérale, la mise
									en place d&apos;une prothèse peut être envisagée (Figure 3).
									La réinsertion des tubérosités (sur lesquelles
									s&apos;attachent les muscles de la coiffe des rotateurs) sur
									la prothèse permet d&apos;améliorer le résultat fonctionnel
									post-opératoire.
								</Typography>
							</Typography>
						</List.Item>
					</List>
					<Figure
						imageName="figure3-prothesedepaule.jpg"
						caption="Figure 3. Fracture complexe et déplacée de l'humérus, indication à la mise en place d'une prothèse d'épaule"
						currentOpTitle="Prothèse d'épaule"
					/>
					<List>
						<List.Item>
							<Typography weight="bold">
								Rupture non réparable de la coiffe des rotateurs :
								<Typography variant="span">
									{" "}
									Une rupture ancienne et non réparable de la coiffe des
									rotateurs, peut être responsable d&apos;une ascension de la
									tête de l&apos;humérus. Dans les formes évoluées, on observe
									une arthrose gléno-humérale (omarthrose excentrée) ainsi
									qu&apos;un frottement de l&apos;extrémité proximale de
									l&apos;humérus contre l&apos;acromion (Figure 4).
								</Typography>
							</Typography>
						</List.Item>
					</List>
					<Figure
						maxWidth="max-w-[400px]"
						imageName="figure4-prothesedepaule.jpg"
						caption="Figure 4. Omarthrose excentrée, témoignant d'une rupture massive et ancienne de la coiffe des rotateurs"
						currentOpTitle="Prothèse d'épaule"
					/>
				</MainPart>
				<MainPart title="Quels sont les examens réalisés pour le bilan pré-opératoire ?">
					<Typography>
						Avant la mise en place d&apos;une prothèse d&apos;épaule , un bilan
						pré-opératoire doit être réalisé :
					</Typography>
					<List>
						<List.Item>
							<Typography weight="bold">
								Imagerie :
								<Typography variant="span">
									{" "}
									radiographies et scanner de l&apos;épaule avec ou sans
									injection de produit de contraste en fonction de la pathologie
									à traiter.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Bilan infectieux :
								<Typography variant="span">
									{" "}
									un bilan dentaire et urinaire est en général demandé afin
									d&apos;éliminer tout foyer infectieux avant la chirurgie.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Bilan cardiaque :
								<Typography variant="span">
									{" "}
									demandé surtout chez les patients âgés avec des comorbidités
									cardio-vasculaires (hypertension artérielle,
									hyper-cholestérolémie, diabète...).
								</Typography>
							</Typography>
						</List.Item>
					</List>
				</MainPart>
				<MainPart title="Quelles sont les différentes prothèses d'épaule ?">
					<Typography>
						La mise en place d&apos;une prothèse totale d&apos;épaule permet de
						remplacer les surfaces articulaires usées par des pièces
						prothétiques. Il existe deux grandes catégories de prothèses : la
						prothèse totale anatomique et la prothèse totale inversée.
					</Typography>
					<List>
						<List.Item>
							<Typography weight="bold">
								La prothèse totale anatomique :
								<Typography variant="span">
									{" "}
									La mise en place de cette prothèse est indiquée en cas
									d&apos;absence de rupture de la coiffe des rotateurs. Son but
									est de reproduire l&apos;anatomie de l&apos;épaule à
									l&apos;aide d&apos;un implant huméral en forme de demi-sphère
									(fixé sur l&apos;humérus) et d&apos;un implant glénoïdien
									ovale (fixé sur la glène de l&apos;omoplate). Si la qualité
									osseuse est bonne, un implant huméral sans tige (« Stemless »)
									peut être utilisé lors de la mise en place de cette prothèse
									(Figure 5).
								</Typography>
							</Typography>
						</List.Item>
					</List>
					<FigureDouble
						maxWidth="max-w-[350px]"
						height="h-[150px]"
						imageNameOne="figure5-prothesedepaule.jpg"
						imageNameTwo="figure5bis-prothesedepaule.jpg"
						caption="Figure 5. Omarthrose excentrée, témoignant d'une rupture massive et ancienne de la coiffe des rotateurs"
						currentOpTitle="Prothèse d'épaule"
					/>
					<List>
						<List.Item>
							<Typography weight="bold">
								La prothèse totale inversée :
								<Typography variant="span">
									{" "}
									La prothèse totale inversée est indiquée en cas de rupture
									importante et non réparable de la coiffe des rotateurs ou en
									cas d&apos;usure très importante de la glène de
									l&apos;omoplate rendant impossible la mise en place d&apos;une
									prothèse totale anatomique. La conception de cette prothèse
									reproduit une « anatomie inversée » : la demi-sphère est fixée
									sur l&apos;omoplate et l&apos;implant humérale est constitué
									d&apos;une tige avec une cupule creuse permettant
									d&apos;accueillir cette sphère (Figure 6). Cette prothèse
									permet ainsi de se passer de la coiffe des rotateurs et
									d&apos;utiliser le muscle deltoïde (qui n&apos;est que très
									rarement abîmé) pour la mobilité de l&apos;épaule.
								</Typography>
							</Typography>
						</List.Item>
					</List>
					<Figure
						imageName="figure6bis-prothesedepaule.jpg"
						caption="Figure 6. Prothèse totale inversée de l'épaule"
						currentOpTitle="Prothèse d'épaule"
					/>
				</MainPart>
				<MainPart title="Comment se déroule l'opération chirurgicale de l'épaule et quelles en sont les suites posts-opératoires ?">
					<Typography>
						L&apos;opération est réalisée sous anesthésie générale. Une{" "}
						<a
							className="underline text-link hover:text-primary"
							href="https://www.passeportsante.net/fr/Maux/examens-medicaux-operations/Fiche.aspx?doc=examen-anesthesie-loco-regionale"
							target="_blank"
							rel="noreferrer"
						>
							anesthésie locorégionale
						</a>{" "}
						y est souvent associée afin de diminuer les douleurs
						post-opératoires. En fin d&apos;intervention, un drain est laissé
						dans l&apos;épaule, pour une durée de 48-72 heures, afin de limiter
						la survenue d&apos;hématome post-opératoire. La durée
						d&apos;hospitalisation est de 3 à 5 jours selon l&apos;évolution.
						L’épaule est en général immobilisée dans un gilet « coude au corps »
						(Figure 7).
					</Typography>
					<Figure
						imageName="figure7-prothesedepaule.jpg"
						caption="Figure 7. Gilet orthopédique coude au corps"
						currentOpTitle="Prothèse d'épaule"
					/>
					<Typography>
						La durée d’immobilisation et la rééducation de l'épaule est variable
						selon le type de prothèse :
					</Typography>
					<List>
						<List.Item>
							<Typography>
								En cas de prothèse anatomique, l'épaule est immobilisée pendant
								45 jours. La rééducation sera initiée dès l'ablation du gilet (à
								J45).
							</Typography>
						</List.Item>
						<List.Item>
							<Typography>
								En cas de prothèse inversée, l'épaule est immobilisée pour une
								durée de 2 à 3 semaines. La rééducation sera initiée également
								dès l'ablation du gilet (à 2-3 semaines).
							</Typography>
						</List.Item>
					</List>
					<Typography>
						La main et le coude peuvent être utilisés normalement dès le
						lendemain de l&apos;intervention. La cicatrisation de la peau est
						obtenue au bout de deux semaines.
					</Typography>
					<Typography>
						La rééducation est débutée à domicile ou dans un centre de
						rééducation (les plus souvent en hôpital de jour) au rythme de 2 à 3
						séances par semaines. A trois mois, le patient est le plus souvent
						autonome pour les gestes de la vie quotidienne. Le résultat
						fonctionnel final est en générale obtenu 6-12 mois après
						l&apos;opération.
					</Typography>
					<Typography>
						Le Dr Ghazanfari utilise un logiciel (voir figure 8 et 9 ci-dessous)
						de planification pré-opératoire 3D (Blueprint) permettant de
						personnaliser et d'améliorer le positionnement des implants selon
						l'anatomie du patient. Cela permet d'allonger considérablement la
						durée de vie de la prothèse et de retarder voire d'éviter toute
						chirurgie de reprise prothétique.
					</Typography>
					<Figure
						imageName="figure8bis-prothesedepaule.jpg"
						caption="Figure 8. Visualisation 3D du positionnement du composant glénoïdien dans le cadre d'une prothèse inversée"
						currentOpTitle="Prothèse d'épaule"
					/>
					<Figure
						imageName="figure9-prothesedepaule.jpg"
						caption="Figure 9. Visualisation 3D du positionnement du composant huméral dans le cadre d'une prothèse inversée"
						currentOpTitle="Prothèse d'épaule"
					/>
				</MainPart>
				<MainPart title="Quelles sont les complications possibles après la mise en place d'une prothèse d'épaule ?">
					<Typography>
						En cas de réalisation de l&apos;opération par un chirurgien
						spécialisé du membre supérieur, les risques de complication sont
						rares. Il faut néanmoins prendre en compte les complications
						suivantes :
					</Typography>
					<List>
						<List.Item>
							<Typography weight="bold">
								Complications liées à l&apos;anesthésie :{" "}
								<Typography variant="span">
									ce risque est variable selon les antécédents du patient. Il
									sera évalué par l&apos;anesthésiste et le cardiologue avant
									l&apos;opération.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Infection :{" "}
								<Typography variant="span">
									rare en raison du dépistage des foyers infectieux avant
									l&apos;opération (bilan dentaire et urinaire),
									l&apos;application des règles d&apos;hygiène et
									l&apos;administration d&apos;une antibioprophylaxie pendant
									l&apos;opération. Le germe le plus souvent incriminé est le
									P.Acnès.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Hématome :{" "}
								<Typography variant="span">
									la mise en place d&apos;un drain (retiré à 48-72h) à la fin de
									l&apos;opération minimise ce risque.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Risque neurologique{" "}
								<Typography variant="span">
									(atteinte du nerf axillaire, étirement du plexus brachial) :
									toutes les précautions nécessaires sont prises pendant
									l&apos;opération afin de prévenir cette complication. Dans la
									majorité des cas, la récupération neurologique est la règle.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Descellement de la prothèse :{" "}
								<Typography variant="span">
									correspondant au lâchage progressif de l&apos;ancrage de la
									prothèse dans l&apos;os. Il s&apos;agit d&apos;une
									complication commune à toutes les prothèses. De nos jours, la
									durée de vie d&apos;une prothèse d&apos;épaule est de 15 à 20
									ans.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Luxation de prothèse :{" "}
								<Typography variant="span">
									principalement observée dans les prothèses inversées.
									Plusieurs facteurs peuvent en être la cause : infection
									post-opératoire, mauvais positionnement des implants,
									raccourcissement excessive de l&apos;humérus, non
									consolidation des tubérosités (en cas de fracture), usure et
									descellement de la prothèse.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Douleur et raideur de l&apos;épaule :{" "}
								<Typography variant="span">
									habituelle dans les suites post-opératoire. La récupération
									est longue (6 à 12 mois) mais le plus souvent favorable avec
									les séances de rééducation.
								</Typography>
							</Typography>
						</List.Item>
						<List.Item>
							<Typography weight="bold">
								Capsulite rétractile{" "}
								<Typography variant="span">
									(ou algodystrophie) : se traduisant par des douleurs et un
									enraidissement de l&apos;épaule pouvant atteindre tout le
									membre supérieur. Il s&apos;agit d&apos;une complication non
									prévisible, pouvant durer 12 à 18.
								</Typography>
							</Typography>
						</List.Item>
					</List>
				</MainPart>
			</div>
		</div>
		<RandomOp type="epaule" />
	</Layout>
);

export default ProtheseDepaule;
